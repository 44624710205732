/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-mfxq0m --style4 --full" name={"uvod"} parallax={false} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19880/168d278bf142431ba2832dfa145b3305_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19880/168d278bf142431ba2832dfa145b3305_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19880/168d278bf142431ba2832dfa145b3305_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19880/168d278bf142431ba2832dfa145b3305_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19880/168d278bf142431ba2832dfa145b3305_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19880/168d278bf142431ba2832dfa145b3305_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19880/168d278bf142431ba2832dfa145b3305_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19880/168d278bf142431ba2832dfa145b3305_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 --parallax flex--center" style={{"maxWidth":1300}} columns={"1"} parallax={true} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 mt--10 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--102" style={{"maxWidth":1276}} content={"<br>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--left fs--43" content={"<span style=\"font-weight: bold; color: rgb(7, 7, 7);\"><br><br><br><br><br><br></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" name={"uboevs7zzq"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"„Připravíme Vaši nemovitost k prodeji, pronájmu i bydlení\""}>
              </Subtitle>

              <Title className="title-box fs--36" content={"<br><br>Co je Home Staging?"}>
              </Title>

              <Text className="text-box fs--22" style={{"maxWidth":1288}} content={"<br>Homestaging (příprava nemovitosti k prodeji/pronájmu) je nedílnou součástí trhu s realitami. Profesionální příprava nemovitosti před prodejem zvyšuje její hodnotu a urychluje samotný prodej."}>
              </Text>

              <Button className="btn-box fs--20" use={"page"} href={"/services#o5ad4v6oqt"} style={{"backgroundColor":"rgba(41,0,255,1)"}} content={"Ukázky realizací<br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"8u251pn8cqr"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" content={"Jak funguje v praxi?"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6vxuefp7y7h"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold;\">1. Konzultace</span>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":399}} content={"Nevíte si rady s prodejem nebo pronájmem nemovitosti? Obraťte se na nás. První konzultace je zdarma. Provedeme analýzu stavu nemovitosti. Analýza je nezbytná pro navržení možných postupů, které budou zapotřebí k uvedení nemovitost do stavu vhodnému k prodeji/pronájmu."}>
              </Text>

              <Button className="btn-box fs--20" use={"page"} href={"/kontakt#l8lca1wp37p"} style={{"backgroundColor":"rgba(46,0,255,1)"}} target={"_blank"} content={"Objednat konzultaci"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19880/b319cd8711c244d499338e5d00c69c0f_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19880/b319cd8711c244d499338e5d00c69c0f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/b319cd8711c244d499338e5d00c69c0f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/b319cd8711c244d499338e5d00c69c0f_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19880/be53f236c083410f947d575acb5c94bb_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19880/be53f236c083410f947d575acb5c94bb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/be53f236c083410f947d575acb5c94bb_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold;\">2. Návrh řešení</span>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":399}} content={"Vše se odvíjí od stavu nemovitosti. Někdy stačí pouze drobná kosmetická úprava. V ostatních případech je zapotřebí odborný řemeslný zákrok.<br><br>"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/kontakt#l8lca1wp37p"} style={{"backgroundColor":"rgba(43,0,255,1)"}} content={"Objednat konzultaci"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"hkmq556srm6"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold;\">3. Realizace</span>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":399}} content={"Nabízíme malířské práce, podlahářské práce, vyklízení/úklid nemovitostí , zahradnické práce, drobné zednické práce a další úpravy/opravy interiérů a jejich vybavení.&nbsp;"}>
              </Text>

              <Button className="btn-box" use={"page"} href={"/services#o5ad4v6oqt"} style={{"backgroundColor":"rgba(45,0,255,1)"}} target={"_blank"} content={"Přehled služeb"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/19880/ea785888cca844e4bf2f878b1634e743_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19880/ea785888cca844e4bf2f878b1634e743_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/ea785888cca844e4bf2f878b1634e743_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/ea785888cca844e4bf2f878b1634e743_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"o-nas"} style={{"backgroundColor":"rgba(31,0,255,1)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--20" style={{"maxWidth":383}} content={"<span style=\"color: rgb(255, 255, 255);\">Slavomír Chalupa</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">+420 606 025 400<br>+420 724 952 477<br>IG: @homestagingkm<br>homestagingkm@gmail.com<br>IČ: 42603021</span>"}>
              </Text>

              <Text className="text-box" content={"Vyklízení Kroměříž, vyklízecí práce Kroměříž, vyklízení nemovitostí Kroměříž, vyklízení bytů Kroměříž, malování Kroměříž, malířské práce&nbsp;Kroměříž, malíři pokojů Kroměříž, maliri pokoju kromeriz, malování pokojů Kroměříž, podlahy&nbsp;Kroměříž, podlahářské práce&nbsp;Kroměříž, podlahář Kroměříž, podlahar Kromeriz, podlaháři Kroměříž, zahradnické práce&nbsp;Kroměříž, údržba zahrad Kroměříž, zahradník Kroměříž"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}